import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Obfuscate from 'react-obfuscate';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass/styled-components';

import Container from './container';

const StyledFooter = styled(Box)``;

const FooterColumn = ({ children }) => (
  <Box mb={[4, 0]} width={[1, '48%']}>
    {children}
  </Box>
);

const FooterTitle = ({ children }) => (
  <Text mb={3} fontWeight="bold">
    {children}
  </Text>
);

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          company {
            name
            address {
              street
              city
            }
          }
          project {
            address {
              street
              city
            }
          }
          contact {
            phone
            email
          }
        }
      }
    }
  `);

  const { company, project, contact } = data.site.siteMetadata;
  const { phone, email } = contact;

  return (
    <StyledFooter as="footer" variant="footer" py={5}>
      <Container>
        <Flex mb={4} flexDirection={['column', 'row']} justifyContent="space-between">
          <FooterColumn>
            <FooterTitle>{company.name}</FooterTitle>

            <Text as="p">
              {company.address.street}
              <br />
              {company.address.city}
            </Text>
          </FooterColumn>

          <FooterColumn>
            <FooterTitle>Adresa pozemků</FooterTitle>

            <Text as="p">
              {project.address.street}
              <br />
              {project.address.city}
            </Text>
          </FooterColumn>
        </Flex>

        <Text>{`Copyright © ${new Date().getFullYear()}. Všechna práva vyhrazena.`}</Text>
      </Container>
    </StyledFooter>
  );
};

FooterColumn.propTypes = {
  children: PropTypes.node.isRequired,
};

FooterTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Footer;

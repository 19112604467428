import { lighten } from 'polished'

const light = {
  breakpoints: ['40em', '52em', '64em'],
  colors: {
    white: '#FFF',
    footer: '#1F1714',
    beige: '#F5F1F0',
    brown: '#4E3A33',
    green: '#63C097',
    red: '#FF473E',
    lightGreen: '#ADEACD',
    darkGreen: '#0F241B',
    text: '#4E3A33',
    inputBorderColor: '#CCB8B3',
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256,
  ],
  shadows: {
    1: '0 3px 12px rgba(0, 0, 0, .125)',
  },
  fontSizes: [
    12, 14, 16, 20, 24, 28, 32, 48, 64,
  ],
  fontWeights: {
    body: 300,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.75,
    heading: 1.25,
  },
  fonts: {
    body: '"Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    system: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  header: {
    background: '#1F1714',
    backgroundOpacity: 0.6,
  },
  text: {
    body: {
      color: 'text',
      lineHeight: 'body',
      mb: 4,
    },
    heading: {
      color: 'brown',
    },
  },
  buttons: {
    primary: {
      py: 3,
      px: 4,
      bg: 'brown',
      color: 'white',
      fontFamily: 'body',
      fontWeight: 'bold',
      height: '4rem',
      borderRadius: '5px',
      lineHeight: '2',
    },
    white: {
      py: 3,
      px: 4,
      bg: 'white',
      color: 'brown',
      fontFamily: 'body',
      fontWeight: 'bold',
      height: '4rem',
      borderRadius: '5px',
      lineHeight: '2',
    },
  },
  variants: {
    footer: {
      bg: 'footer',
      color: lighten(0.8, '#1F1714'),
    },
    herotitle: {
      color: 'white',
    },
    dark: {
      bg: 'brown',
      color: 'white',
    },
    light: {
      bg: 'beige',
      color: 'text',
    },
    lightGreen: {
      bg: 'lightGreen',
      color: 'text',
    },
    'dark-contours': {
      bg: 'brown',
      color: 'white',
    },
  },
}

export default light

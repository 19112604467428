import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from 'rebass/styled-components'

const SectionTitle = ({
  children,
  as = 'h2',
  mb = 4,
  mx = null,
  color = null,
  textAlign = 'center',
  fontSize = 5,
  maxWidth = null,
  sx,
  ...props
}) => (
  <Heading
    as={as}
    mb={mb}
    mx={mx}
    color={color}
    fontSize={fontSize}
    textAlign={textAlign}
    maxWidth={maxWidth}
    sx={sx}
    {...props}
  >
    {children}
  </Heading>
)

SectionTitle.propTypes = {
  children: PropTypes.node,
  as: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  mx: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  color: PropTypes.string,
  textAlign: PropTypes.string,
  fontSize: PropTypes.number,
  maxWidth: PropTypes.string,
  sx: PropTypes.object,
}

export default SectionTitle

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Flex, Button } from 'rebass/styled-components'
import { rgba } from 'polished'

import Container from './container'
import Nav from './nav'
import Logo from '../images/inline/logo.svg'

const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  bottom: ${(props) => (props.menuOpen ? 0 : 'auto')};
  left: 0;
  background-color: ${({ theme, menuOpen }) => rgba(theme.header.background, menuOpen ? 0.95 : theme.header.backgroundOpacity)};
  transition: background-color 200ms ease-in-out;
  z-index: 20;
`

const StyledLogo = styled(Flex)`
  margin-right: 4rem;
  line-height: 0;
  padding: 1rem 0;
`

const MenuToggle = styled(Button)`
  cursor: pointer;
`

const Header = ({ siteTitle }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <StyledHeader menuOpen={menuOpen}>
      <Container>
        <Flex
          flexDirection={['row', 'row']}
          alignItems={['center', 'center']}
          flexWrap="wrap"
        >
          <StyledLogo justifyContent={['center']}>
            <Link to="/">
              <Logo title={siteTitle} style={{ width: 'auto', height: '2.75rem' }} />
            </Link>
          </StyledLogo>

          <MenuToggle
            sx={{
              height: 'auto',
              py: 2,
              ml: ['auto', 3],
              display: ['block', 'none'],
            }}
            type="button"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            Menu
          </MenuToggle>

          <Nav menuOpen={menuOpen} />
        </Flex>
      </Container>
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header

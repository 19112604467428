import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { Flex } from 'rebass/styled-components'

const NavWrapper = styled(Flex)`
  overflow: ${(props) => (props.height[0] === '0' ? 'hidden' : 'visible')};
`

const Menu = styled(Flex)``

const NavItem = styled.li`
  margin-right: 2rem;
`

const NavLink = styled(Link)`
  display: block;
  padding: 0.25rem 0 0.25rem 1rem;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  min-height: 2.75rem;
  line-height: 2.75rem;

  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 1rem 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    transform-origin: 0 50%;
    transform: scaleX(0);
    transition: all 200ms ease-in-out;

    @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
      width: 100%;
      height: 4px;
      right: 0;
    }
  }

  &:hover:before,
  &:focus:before,
  &.active:before {
    transform: scaleX(1);
  }
`

const Nav = ({ menuOpen = false }) => {
  const data = useStaticQuery(graphql`
    query MenuItems {
      site {
        siteMetadata {
          menu {
            label
            slug
          }
        }
      }
    }
  `)
  const menuItems = data.site.siteMetadata.menu

  return (
    <NavWrapper
      as="nav"
      flexGrow={[1, 0]}
      width={['100%', 'auto']}
      height={[menuOpen ? 'auto' : '0', 'auto']}
      my={['auto', '0']}
    >
      {menuItems && (
        <Menu as="ul" flexDirection={['column', 'row']} width="100%">
          {menuItems.map((item) => (
            <NavItem key={item.slug}>
              <NavLink to={item.slug} activeClassName="active">
                {item.label}
              </NavLink>
            </NavItem>
          ))}
        </Menu>
      )}
    </NavWrapper>
  )
}

Nav.propTypes = {
  menuOpen: PropTypes.bool,
}

export default Nav

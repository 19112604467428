import { createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'

const GlobalStyle = createGlobalStyle`
  html {
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
  }

  body {
    font-family: ${({ theme }) => theme.fonts.body};
  }

  :focus {
    /* outline: 3px solid ${({ theme }) => rgba(theme.colors.green, 0.8)}; */
    outline: none;
  }

  a {
    color: inherit;
  }

  p {
    margin-bottom: 1rem;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  strong {
    font-weight: bold;
  }
`

export default GlobalStyle

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Reset } from 'styled-reset'
import { ThemeProvider } from 'styled-components'
import { Box } from 'rebass/styled-components'

import GlobalStyle from '../styles/globalStyle'
import light from '../themes/default'
import Header from './header'
import Footer from './footer'

const Layout = ({ children, hasHero = true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={light}>
      <Box sx={{ color: 'text', lineHeight: 'body', pt: hasHero ? '0' : '4.75rem' }}>
        <Reset />
        <GlobalStyle />

        <Header siteTitle={data.site.siteMetadata.title} />

        <main>{children}</main>

        <Footer />
      </Box>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hasHero: PropTypes.bool,
}

export default Layout

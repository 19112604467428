import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

const Section = ({ children, ...props }) => {
  return (
    <Box as="section" py={[4, 5]} {...props}>
      {children}
    </Box>
  )
}

Section.propTypes = {
  children: PropTypes.node,
}

export default Section
